<template>
  <div>
    <b-card header-tag="header" style="min-height: 750px;">
      <b-row>
        <b-col cols="12">
          <b-row>
            <b-col sm="12" md="6" lg="5">
              <b-card no-body>
                <b-card-header header-bg-variant="secondary" header-text-variant="dark" class="p-1">
                  <img src="@/icon/2333399.png" style="width: 2em;"> Sistem İlişkili Sayfalar
                  <b-button v-if="user && user.permissions_result && user.permissions_result.indexOf('wisdom') !== -1" variant="secondary" size="" class="pull-right" @click="f_addNewChildWisdomData('wdm232')" title="yeni döküman ekle">
                    <i class="fa fa-plus"></i>
                  </b-button>
                </b-card-header>
                <template v-for="(wdmr232, wdmr232_ind) in d_wdmr232List">
                  <b-row style="margin: 0px;" :style="('wdm232_' + d_selectedwdmr232Data.id) === wdmr232.key ? 'color: green; margin: 0px; cursor: pointer;' : 'margin: 0px; cursor: pointer;'">
                    <b-col sm="12" lg="8" @click="f_getWdmrData(wdmr232.key)">
                      <img src="@/icon/42729.png" style="width: 2em;"> {{ wdmr232.label }} <small>[ {{ wdmr232.key }} ]</small>
                    </b-col>
                    <b-col sm="12" lg="4">
                      <!-- <div @click="f_goToDocumentDetails(wdmr232.key)"><a href="javascript:">detaylar...</a></div> -->
                      <b-dropdown v-if="user && user.permissions_result && user.permissions_result.indexOf('wisdom') !== -1 && ('wdm232_' + d_selectedwdmr232Data.id) === wdmr232.key" class="pull-right" variant="secondary" text="İşlemler" size="sm">
                        <b-dropdown-item @click="f_editChildWdmr('wdm232')">düzenle</b-dropdown-item>
                        <!-- <b-dropdown-item @click="f_goToDocumentDetails(wdmr232.key)">döküman detaylarına git</b-dropdown-item> -->
                      </b-dropdown>
                    </b-col>
                  </b-row>
                </template>
              </b-card>
            </b-col>
            <b-col sm="12" md="6" lg="7">
              <template v-if="d_selectedwdmr232Data && d_selectedwdmr232Data.id">
                <b-card no-body>
                  <b-card-header header-bg-variant="sedondary" header-text-variant="dark" class="p-1">
                    {{ d_selectedwdmr232Data.label }}
                  </b-card-header>
                  <div>
                    <wisdom-data-show :change="d_wisdomData.change" :view_mode="d_wisdomData.view_mode" :data="d_selectedwdmr232Data" :data_type="'wdm232'" :option_data="d_wisdomData.option_data['wdm232']"></wisdom-data-show>
                  </div>
                </b-card>
              </template>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
    <modal v-if="d_wisdomData.show" @close="d_wisdomData.show = false" :width="'1000'">
      <h3 slot="header">{{ d_wisdomData.option_data[d_wisdomData.data_type].name.label }}</h3>
      <div slot="body">
        <wisdom-data-modal ref="wisdom_data_modal" :data="d_wisdomData.wisdom_data" :data_type="d_wisdomData.data_type" :option_data="d_wisdomData.option_data"></wisdom-data-modal>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-success" v-on:click="f_saveChildWdm()">{{ $t('wdm16.4061') }}</button>
        <button type="button" class="btn btn-danger" @click="d_wisdomData.show = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
  </div>
</template>

<script type="text/javascript">
import {
  default as Modal
} from '@/components/widgets/Modal';
import {
  default as WisdomDataShow
} from '@/components/widgets/WisdomDataShow';
import {
  default as WisdomDataModal
} from '@/components/widgets/WisdomDataModal';
import GlobalService from '@/services/global';
import { ClsWdmrManage } from "@/services/public/wdmr_manage";
export default {
  name: 'PageSettings',
  computed: {},
  props: {},
  components: {
    WisdomDataModal,
    WisdomDataShow,
    Modal
  },
  data () {
    return {
      user: '',
      d_wdmr232List: [],
      d_selectedwdmr232Data: {},
      d_wisdomData: {
        'view_mode': 'table',
        'change': '0',
        'show': false,
        'wisdom_data': {},
        'data_type': '',
        'wisdom_op': '',
        'option_data': {}
      },
      d_wdmr233: {
        'id': 1
      }
    }
  },
  created () {
    this.user = JSON.parse(localStorage.getItem('user'));
  },
  mounted () {
    if (this.d_wdmr233) {
      this.f_getWdmr232List();
      this.f_wdmList();
    }
  },
  methods: {
    f_goToDocumentDetails: function (wdmr232_key) {
      let route_data = {
        'name': 'documenttree',
        'path': 'documenttree',
        'query': {
          'wdmr232': wdmr232_key.split('_')[1]
        }
      };
      this.$router.push(route_data);
    },
    f_getWdmrData: function (wdmr_key) {
      // Examples: wdmr_key => wdm7_1, wdm7_2, ......
      let data = { 'key': wdmr_key, 'bucket': 'wisdom' };
      GlobalService.get_one_cb_document(data)
        .then(resp => {
          let msg = '';
          if (resp.data.status === "success") {
            this.d_selectedwdmr232Data = resp.data.result;
          } else {
            this.d_selectedwdmr232Data = '';
            console.log('errorr f_getWdmrData : ', resp.data.message);
          }
          this.f_changeFunc();
        });
    },
    f_editChildWdmr: function (data_type) {
      this.d_wisdomData.wisdom_op = "edit";
      this.d_wisdomData.data_type = data_type;
      this.d_wisdomData.wisdom_data = JSON.parse(JSON.stringify(this.d_selectedwdmr232Data));
      this.d_wisdomData.show = true;
    },
    f_saveChildWdm: function () {
      let parent_wdm = '';
      let child_wdm = '';
      let relation_type = 'keys';
      if (this.d_wisdomData.data_type === 'wdm232') {
        parent_wdm = {
          'label': '',
          'key': 'wdm233_' + this.d_wdmr233.id,
          'bucket': 'wisdom',
          'type': 'wdm233'
        };
        child_wdm = {
          'label': '',
          'key': 'wdm_wisdom_wdm232_v2.0',
          'bucket': 'wisdom',
          'type': 'wdm232'
        };
        relation_type = 'keys';
      }
      ClsWdmrManage.save_child_wdmr(this.d_wisdomData, parent_wdm, child_wdm, relation_type)
        .then(resp => {
          // console.log(resp);
          let new_wdmr232 = {
            'label': resp.wdmr.label ? resp.wdmr.label : '',
            'key': 'wdm232_' + resp.wdmr.id
          };
          if (this.d_wisdomData.wisdom_op === 'new') {
            this.d_wdmr232List.push(new_wdmr232);
          } else if (this.d_wisdomData.wisdom_op === 'edit') {
            for (let i in this.d_wdmr232List) {
              if (this.d_wdmr232List[i].key = 'wdm232_' + resp.wdmr.id) {
                this.d_wdmr232List[i].label = resp.wdmr.label ? resp.wdmr.label : '';
                break;
              }
            }
            this.d_selectedwdmr232Data = resp.wdmr;
          }
          this.d_wisdomData.show = false;
          this.f_changeFunc();
        }, resp => {
          alert('error ', resp);
        });
    },
    f_changeFunc: function () {
      if (this.d_wisdomData.change === '0') {
        this.d_wisdomData.change = '1';
      } else {
        this.d_wisdomData.change = '0';
      }
      this.$forceUpdate();
    },
    f_addNewChildWisdomData: function (data_type) {
      ClsWdmrManage.prepare_new_wisdom_data(this.d_wisdomData, data_type)
      this.d_wisdomData.wisdom_op = 'new';
      this.d_wisdomData.show = true;
    },
    f_wdmList: function () {
      let data = {
        'key_list': ['wdm_wisdom_wdm232_v2.0'],
        'bucket': 'option'
      };
      GlobalService.get_multiple_cb_documents(data)
        .then(resp => {
          if (resp.data.status === 'success') {
            for (let i in resp.data.result) {
              this.d_wisdomData.option_data[resp.data.result[i].parent] = resp.data.result[i];
            }
          } else {
            console.log('error ', resp.data.message);
          }
        });
    },
    f_getWdmr232List: function () {
      this.d_wdmr232List = [];
      let parent_wdm = {
        'label': '',
        'key': 'wdm233_' + this.d_wdmr233.id,
        'bucket': 'wisdom',
        'type': 'wdm233'
      };
      let child_wdm = {
        'label': '',
        'bucket': 'wisdom',
        'type': 'wdm232'
      };
      let relation_type = 'keys';
      ClsWdmrManage.get_related_wdmr_list(parent_wdm, child_wdm, relation_type)
        .then(resp => {
          if (resp && resp.list) {
            this.d_wdmr232List = resp.list;
          }
        }, resp => {
          console.log('error ', resp);
        });
    }
  }
}

</script>

